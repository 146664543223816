import axios from "axios";
import { parseCookies } from "nookies";

const test = axios.create({
  baseURL: process.env.REACT_APP_URL ?? "/",
});

const cookies = parseCookies();
test.defaults.headers.post["X-CSRFToken"] = cookies.csrftoken;

test.interceptors.request.use((config) => {
  if (localStorage.getItem("token")) {
    const token = "Token " + localStorage.getItem("token");
    config.headers.Authorization = token;
  }

  return config;
});

export default test;
