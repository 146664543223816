import { useLayoutEffect, useRef, useState } from "react";

export const VerticalLine = ({ width = 10, isLast }) => {
  const [height, setHeight] = useState(0);
  const svgBlock = useRef();

  useLayoutEffect(() => {
    if (svgBlock.current) {
      setHeight(svgBlock.current.clientHeight);
    }
  }, []);

  if (isLast) {
    return null;
  }

  return (
    <svg
      width={width}
      height="100%"
      ref={svgBlock}
      style={{ position: "absolute" }}
    >
      <polyline
        points={`0 0, 0 ${height}`}
        style={{
          fill: "transparent",
          stroke: "black",
          strokeWidth: 2,
        }}
      />
    </svg>
  );
};
