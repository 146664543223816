import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Card, DatePicker, Layout, Form, Select, Button, Modal } from "antd";
import { Layout as LayoutCustom } from "../../components";
import {
  getAnalyticsChart,
  getAnalyticsLookup,
} from "../../redux/analyticsSlice";
import ModalPareto from "./ModalPareto";
import ModalShewhart from "./ModalShewhart";
import plot from "./plotlly.html";

import "./style.css";

const types = [
  {
    label: "Выполненные",
    value: "performed",
  },
  {
    label: "Дефекты",
    value: "defects",
  },
];

const Analytics = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [ifrLoad, setIfrLoad] = useState(false);
  const [disButton, setDisButton] = useState(true);
  const [modalType, setModalType] = useState("");
  const { lookup, loading, error, chart } = useSelector(
    (state) => state.analytics,
    shallowEqual
  );

  const ref = useRef(null);

  useEffect(() => {
    setIfrLoad(false);
    if (chart) {
      ref.current.addEventListener("load", () => {
        setIfrLoad(true);
      });
    }
  }, [chart]);

  useEffect(() => {
    if (ifrLoad) {
      ref?.current?.contentWindow?.postMessage?.(chart, "*");
    }
  }, [chart, ifrLoad]);

  useEffect(() => {
    dispatch(getAnalyticsLookup());
  }, [dispatch]);

  const onFinish = (val) => {
    dispatch(getAnalyticsChart(val));
  };

  const checkValue = (_, { type, date, worker_id }) => {
    setDisButton(!(type === "defects" && date?.length && worker_id));
  };

  return (
    <LayoutCustom title="Аналитика">
      <Card>
        <Layout>
          <Layout.Sider className="siteLB">
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              onValuesChange={checkValue}
              initialValues={{
                type: "performed",
              }}
            >
              <Form.Item label="Модель" name="model_id">
                <Select
                  loading={loading}
                  options={lookup.shoes_models}
                  allowClear
                />
              </Form.Item>
              <Form.Item label="Заказ" name="order_id">
                <Select loading={loading} options={lookup.orders} allowClear />
              </Form.Item>
              <Form.Item label="Дата" name="date">
                <DatePicker.RangePicker
                  loading={loading}
                  placeholder={["Начальная дата", "Конечная дата"]}
                  format="DD.MM.YYYY"
                />
              </Form.Item>
              <Form.Item label="Рабочий" name="worker_id">
                <Select loading={loading} options={lookup.workers} allowClear />
              </Form.Item>
              <Form.Item label="Тип" name="type">
                <Select loading={loading} options={types} />
              </Form.Item>
              <Button
                htmlType="submit"
                style={{
                  width: "100%",
                }}
                loading={loading}
              >
                Построить график
              </Button>
            </Form>
          </Layout.Sider>
          <Layout.Content className="siteLB">
            <div className="actionButton">
              <Button
                disabled={disButton}
                onClick={() => setModalType("pareto")}
                style={{ marginRight: "15px" }}
              >
                Диаграмма Парето
              </Button>
              <Button
                disabled={disButton}
                onClick={() => setModalType("shewhart")}
              >
                Контрольные карты Шухарта
              </Button>
            </div>
            {error && <div className="center">{error}</div>}
            {chart && (
              <iframe
                ref={ref}
                title="pareto"
                style={{ width: "100%", height: "100vh" }}
                srcDoc={plot}
              />
            )}
            <Modal
              width={1000}
              open={modalType === "pareto"}
              destroyOnClose
              cancelText="Закрыть"
              okText={() => null}
              onCancel={() => setModalType("")}
            >
              <ModalPareto values={form.getFieldsValue()} />
            </Modal>
            <Modal
              width={1000}
              open={modalType === "shewhart"}
              destroyOnClose
              cancelText="Закрыть"
              okText={() => null}
              onCancel={() => setModalType("")}
            >
              <ModalShewhart values={form.getFieldsValue()} />
            </Modal>
          </Layout.Content>
        </Layout>
      </Card>
    </LayoutCustom>
  );
};

export default Analytics;
