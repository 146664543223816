import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api/api";

const initialState = {
  models: [],
  model: {
    tech_processes: [],
  },
  indicators: [],
  indicators_params: {},
  loading: false,
};

export const fetchModels = createAsyncThunk("models/fetchModels", async () => {
  const response = await api.get("/api/shoes-models/");
  return response.data;
});

export const getModelDetail = createAsyncThunk("models/id", async (id) => {
  const response = await api.get(`/api/shoes-models/${id}/`);
  return response.data;
});

export const getQualityIndicators = createAsyncThunk(
  "models/indicators",
  async () => {
    const response = await api.get(`/api/directories/quality_indicators/`);
    return response.data;
  }
);

export const getQualityIndicatorsParams = createAsyncThunk(
  "models/indicatorsParams",
  async (id) => {
    const response = await api.get(
      `/api/shoes_models/${id}/quality_indicators/`
    );
    return response.data;
  }
);

export const setQualityIndicatorsParams = createAsyncThunk(
  "models/indicatorsParams",
  ({ id, formatValues }) => {
    return api.post(
      `/api/shoes_models/${id}/quality_indicators/`,
      formatValues
    );
  }
);

export const modelsSlice = createSlice({
  name: "models",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchModels.pending]: (state) => {
      state.loading = true;
    },
    [fetchModels.rejected]: (state) => {
      state.loading = false;
    },
    [fetchModels.fulfilled]: (state, action) => {
      state.models = action.payload;
      state.loading = false;
    },
    [getModelDetail.pending]: (state) => {
      state.loading = true;
    },
    [getModelDetail.rejected]: (state) => {
      state.loading = false;
    },
    [getModelDetail.fulfilled]: (state, action) => {
      state.model = action.payload;
      state.loading = false;
    },
    [getQualityIndicators.fulfilled]: (state, action) => {
      state.indicators = action.payload;
    },
    [getQualityIndicatorsParams.fulfilled]: (state, action) => {
      state.indicators_params = action.payload;
    },
  },
});

export const act = modelsSlice.actions;

export default modelsSlice.reducer;
