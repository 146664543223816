import { InputNumber } from "./inputNumber";
import { Line } from "./line";
import { VerticalLine } from "./verticalLine";

export const DeepItems = ({ currentIndex, currentList, deepList }) => {
  if (deepList?.length === 0) {
    return null;
  }

  return (
    <div style={{ position: "relative" }}>
      <VerticalLine isLast={currentIndex === currentList?.length - 1} />
      <div>
        {deepList?.map((itemInnerInner, indexInnerInner) => (
          <div
            key={itemInnerInner.id}
            style={{
              display: "flex",
              marginLeft: "18px",
              position: "relative",
            }}
          >
            <Line
              index={indexInnerInner}
              width={10}
              lastIndex={indexInnerInner === deepList?.length - 1}
            />
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: indexInnerInner === 0 ? "8px" : 0,
                paddingBottom: "5px",
                paddingLeft: "16px",
              }}
            >
              {itemInnerInner.title}
              <InputNumber
                name={itemInnerInner.id}
                size="small"
                style={{ width: "35px", minWidth: "35px" }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
