import { Button, Modal } from "antd";
import React, { useEffect, useRef } from "react";
import { Chart, registerables } from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import { getWorkerAnalyticsChart } from "../../redux/workersSlice";

Chart.register(...registerables);

const ModalAnalytics = ({ modalOpen, setModalOpen }) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const { chartInfo } = useSelector((state) => state.workers);

  useEffect(() => {
    dispatch(
      getWorkerAnalyticsChart({
        worker_id: modalOpen.item.workerId,
        model_id: modalOpen.item.model.id,
        start_date: null,
        end_date: null,
      })
    );
  }, [dispatch, modalOpen]);

  useEffect(() => {
    const test = ref.current.getContext("2d");
    const ids = chartInfo.reduce((acc, item) => [...acc, item.date], []);
    const data1 = chartInfo.reduce((acc, item) => [...acc, item.performed], []);
    const data2 = chartInfo.reduce((acc, item) => [...acc, item.defects], []);
    const chart = new Chart(test, {
      type: "line",
      data: {
        labels: ids,
        datasets: [
          {
            label: "Выполнено",
            data: data1,
            backgroundColor: "#198754",
            borderColor: "#198754",
          },
          {
            label: "Дефекты",
            data: data2,
            backgroundColor: "#dc3545",
            borderColor: "#dc3545",
          },
        ],
      },
    });
    return () => chart.destroy();
  }, [chartInfo]);

  return (
    <Modal
      open={modalOpen.open}
      onCancel={() => setModalOpen({ open: false, item: {} })}
      footer={
        <Button
          type="primary"
          onClick={() => setModalOpen({ open: false, item: {} })}
        >
          Закрыть
        </Button>
      }
    >
      <canvas width="1000" height="500" ref={ref} />
    </Modal>
  );
};

export default ModalAnalytics;
