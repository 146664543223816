import React from "react";
import { Button } from "antd";
import moment from "moment";
import html2canvas from "html2canvas";
function downloadImage(dataUrl, name = "") {
  const a = document.createElement("a");

  a.setAttribute("download", `${name}-${moment().format("DD-MM-YYYY")}.png`);
  a.setAttribute("href", dataUrl);
  a.click();
}

export const SavePNGButton = ({
  containerRef,
  fullScreen,
  frame = false,
  name = "",
  position = "center",
  style = {},
  id = "",
}) => {
  const onClick = async () => {
    if (!frame) {
      if (fullScreen) {
        html2canvas(document.getElementsByTagName("main")[0], {
          windowWidth: document.getElementsByTagName("main")[0].clientWidth,
          width: document.getElementsByTagName("main")[0].clientWidth,
          windowHeight: document.getElementsByTagName("main")[0].clientHeight,
          scale: 1,
          logging: false,
          ignoreElements: (el) => el.id === id,
        }).then(function (canvas) {
          const dataURL = canvas.toDataURL();
          downloadImage(dataURL, name);
        });
      } else {
        html2canvas(containerRef.current || containerRef, {
          windowWidth:
            containerRef.current?.clientWidth || containerRef.clientWidth,
          width: containerRef.current?.clientWidth || containerRef.clientWidth,
          windowHeight:
            containerRef.current?.clientHeight || containerRef.clientHeight,
          scale: 1,
          allowTaint: true,
          logging: false,
          ignoreElements: (el) => el.id === id,
        }).then(function (canvas) {
          const dataURL = canvas.toDataURL();
          downloadImage(dataURL, name);
        });
      }
    } else {
      html2canvas(
        document.getElementsByTagName("iframe")[0].contentWindow.document.body,
        {
          scale: 1,
          logging: false,
          ignoreElements: (el) => el.id === id,
        }
      ).then(function (canvas) {
        const dataURL = canvas.toDataURL();
        downloadImage(dataURL, name);
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: position ?? frame ? "start" : "center",
        ...style,
      }}
      id={id}
    >
      <Button className="download-btn" onClick={onClick} danger>
        Сохранить
      </Button>
    </div>
  );
};
