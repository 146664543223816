import React from "react";
import { Alert, Container } from "react-bootstrap";
import styled from "styled-components";

import LayoutHeader from "./Header";

import "./style.css";

const Layout = ({ children, title }) => {
  return (
    <>
      <LayoutHeader />
      <Main>
        <Container className="container-full mt-4">
          {title && (
            <Alert
              className="mx-auto py-2"
              variant="secondary"
              style={{ background: "rgba(255,255,255, 0.8)" }}
            >
              <h3 className="m-0 text-dark text-center">{title}</h3>
            </Alert>
          )}
          <div className="mt-4" style={{ height: "100%" }}>
            {children}
          </div>
        </Container>
      </Main>
    </>
  );
};

export default Layout;

const Main = styled.main`
  display: flex;
  min-height: 100vh;
  padding-top: 56px;
  padding-bottom: 80px;
  background-color: #fff;
`;
