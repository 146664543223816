import React from "react";
import { InputNumber as Input } from "antd";
import FormItem from "antd/es/form/FormItem";

import styles from "./styles.module.css";

export const InputNumber = ({ name, ...props }) => {
  return (
    <FormItem name={name} noStyle>
      <Input {...props} className={styles.number} controls={false} max={10} />
    </FormItem>
  );
};
