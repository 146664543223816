import React, { useEffect, useState } from "react";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import {
  getWarehouseReady,
  sentWarehouseComplete,
} from "../../../redux/warehouseStatusSlice";
import { Button, DatePicker, Form, Input, Modal, Table } from "antd";

const TableModels = ({ data }) => {
  const columns = [
    {
      title: "Название",
      dataIndex: "title",
    },
    {
      title: "Количество",
      dataIndex: "quantity",
    },
  ];

  return (
    <Table columns={columns} dataSource={data} rowKey="id" pagination={false} />
  );
};

const disabledDate = (current, end) => {
  return current && current < moment(end);
};

const CompletedContent = ({ setTab }) => {
  const dispatch = useDispatch();
  const { ready, loading } = useSelector((state) => state.wareStatus);
  const [modal, setModal] = useState({ open: false, item: {} });

  useEffect(() => {
    dispatch(getWarehouseReady());
  }, [dispatch]);

  const readySend = (val) => {
    const { departure_date } = val;
    dispatch(
      sentWarehouseComplete({
        id: modal.item.id,
        departure_date: moment(departure_date).format("YYYY-MM-DD"),
      })
    );
    setModal({ open: false, item: {} });
    setTab("2");
    dispatch(getWarehouseReady());
  };

  const columns = [
    {
      title: "Заказчик",
      dataIndex: "customer",
    },
    {
      title: "Дата приёма",
      dataIndex: "begin_date",
      render: (date) => moment(date).format("DD.MM.YYYY"),
    },
    {
      title: "Дата отправки",
      dataIndex: "departure_date",
      render: (date) => moment(date).format("DD.MM.YYYY"),
    },
    {
      title: "Действия",
      dataIndex: "",
      render: (id, item) => (
        <Button
          onClick={() => {
            setModal({ open: true, item });
          }}
        >
          Оформить отправку
        </Button>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={ready}
        loading={loading}
        pagination={false}
        rowKey="id"
        expandable={{
          expandedRowRender: (record) => (
            <TableModels data={record.order_shoes_models} />
          ),
        }}
      />
      <Modal
        title="Заказ"
        open={modal.open}
        destroyOnClose
        okText="Оформить"
        cancelText="Отмена"
        okButtonProps={{
          htmlType: "submit",
          form: "myForm",
        }}
        cancelButtonProps={{
          danger: true,
        }}
        onCancel={() => setModal({ open: false, item: {} })}
      >
        <Form
          id="myForm"
          name="form"
          onFinish={readySend}
          initialValues={{
            ...modal.item,
            begin_date: moment(modal.item.begin_date),
            departure_date: moment(modal.item.departure_date),
          }}
        >
          <Form.Item name="customer">
            <Input disabled />
          </Form.Item>
          <Form.Item name="begin_date">
            <DatePicker disabled />
          </Form.Item>
          <Form.Item name="departure_date">
            <DatePicker
              disabledDate={(current) =>
                disabledDate(current, modal.item.begin_date)
              }
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CompletedContent;
